import React from "react"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"
import Grid from "../components/Grid"
import Wrapper from "../components/Wrapper"
import CollectionTeaser from "../components/CollectionTeaser"
import ParseSEOData from "../components/ParseSEOData"

export const shopQuery = graphql`
  query ShopQuery($lang: String) {
    prismic {
      allShops(lang: $lang) {
        edges {
          node {
            meta_description
            meta_title
            share_image
            heading
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
      allCollections(sortBy: rank_ASC, lang: $lang) {
        edges {
          node {
            title
            heading
            from_price
            featured_detail_image
            featured_image_portrait
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
    }
  }
`

const Shop = ({ data }) => {
  let doc
  let collections = data.prismic.allCollections.edges
  let heading = get(doc, "node.heading[0].text")

  if (typeof window !== "undefined") {
    // shop url uid
    let urlPathname = window.location.pathname
    if (urlPathname.endsWith('/')) urlPathname = urlPathname.slice(0, -1);
    if (urlPathname.startsWith('/')) urlPathname = urlPathname.substring(1);
    const urlPathnameArray = urlPathname.split('/')
    urlPathname = urlPathnameArray[urlPathnameArray.length - 1];
    // shop lang
    let urlLanguage = urlPathnameArray[0] === urlPathname ? 'da-dk' : urlPathnameArray[0]
    doc = data.prismic.allShops.edges.find(shop => shop.node._meta.uid === urlPathname && shop.node._meta.lang === urlLanguage)

    collections = collections.filter(collection => {
      const collectionHeading = collection.node.heading[0].text.toLowerCase()
      if (!collectionHeading) return true;
      if (urlPathname.includes('kontrakt') || urlPathname.includes('contract')) {
        return collectionHeading.includes('kontrakt') && collection.node._meta.lang === urlLanguage
      } else {
        return !collectionHeading.includes('kontrakt') && !collectionHeading.includes('contract');
      }
    })

    heading = get(doc, "node.heading[0].text")
  }

  if (!doc) doc = data.prismic.allShops.edges.pop()

  return (
    <>
      <ParseSEOData data={doc.node} />
      <div className="shop">
        {heading && (
          <header class="mb--xxl">
            <div class="samples-header-inner">
              <h1 class="fs--xl lh--s">{heading}</h1>
            </div>
          </header>
        )}
        <Wrapper>
          <Grid>
            {
              collections.map((collection, index) => {
                const node = get(collection, "node")
                const title = get(node, "title[0].text")
                const heading = get(node, "heading[0].text")
                const prismicImage = get(node, "featured_image_portrait")
                const detailPrismicImage = get(node, "featured_detail_image")
                const meta = get(node, "_meta")
                const price = get(node, "from_price")

                return (
                  <CollectionTeaser
                    key={index}
                    title={title}
                    heading={heading}
                    prismicImage={prismicImage}
                    detailPrismicImage={detailPrismicImage}
                    meta={meta}
                    price={price}
                  />
                )
              })
            }
            {/* <StaticQuery
              query={shopQuery}
              render={data => {
                let collectionsData = data.prismic.allCollections.edges
                return collections.map((collection, index) => {
                  const node = get(collection, "node")
                  const title = get(node, "title[0].text")
                  const heading = get(node, "heading[0].text")
                  const prismicImage = get(node, "featured_image_portrait")
                  const detailPrismicImage = get(node, "featured_detail_image")
                  const meta = get(node, "_meta")
                  const price = get(node, "from_price")

                  return (
                    <CollectionTeaser
                      key={index}
                      title={title}
                      heading={heading}
                      prismicImage={prismicImage}
                      detailPrismicImage={detailPrismicImage}
                      meta={meta}
                      price={price}
                    />
                  )
                })
              }}
            /> */}
          </Grid>
        </Wrapper>
      </div>
    </>
  )
}

Shop.query = shopQuery

export default Shop
